import * as THREE from 'three'
import Experience from "./Experience";

export default class Camera{
    constructor(){
        // Setup
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.cameraGroup = new THREE.Group()

        this.container = document.querySelector('.container')

        this.sizes.on('mousemove', () => {
            this.updateCursor()
        })

        this.setInstance()
    }

    setInstance(){
        this.instance = new THREE.PerspectiveCamera(45, this.sizes.aspectRatio, 0.01, 100)
        this.cameraGroup.position.z = 3
        this.cameraGroup.add(this.instance)

        this.scene.add(this.cameraGroup)
    }

    resize(){
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update(){
        this.instance.position.x = (this.container.scrollLeft / this.sizes.width) * 5
    }

    updateCursor(){
        this.cameraGroup.position.x = this.sizes.cursor.x
        this.cameraGroup.position.y = this.sizes.cursor.y
    }
}