import EventEmitter from './EventEmitter.js'

export default class Sizes extends EventEmitter{
    constructor(){
        console.log('Inside Sizes constructor');

        super()

        // Setup
        this.width = window.innerWidth
        this.height = window.innerHeight
        this.aspectRatio = this.width / this.height
        this.pixelRatio = Math.min(window.devicePixelRatio, 2)
        this.cursor = {}
        this.cursor.x = 0
        this.cursor.y = 0

        // Resize event
        window.addEventListener('resize', () => {
            this.pixelRatio = Math.min(window.devicePixelRatio, 2)

            this.trigger('resize')
        })

        // Mouse Move event
        window.addEventListener('mousemove', (event) => {
            this.cursor.x = (event.clientX / this.width) * 0.4
            this.cursor.y = - (event.clientY / this.height) * 0.4

            this.trigger('mousemove')
        })
    }

    resize(){
        this.width = window.innerWidth
        this.height = window.innerHeight
    }
}