export default class Debug{
    constructor(){
        this.info = document.querySelector('.info')
        this.active = window.location.hash === '#info'

        if(this.active){
            console.log('active');
        }
    }

    check(){
        this.active = window.location.hash === '#info'

        if(this.active){
            info.classList.add('is-visible');
        }
        else{
            info.classList.remove('is-visible');
        }
    }
}