import './style.css'
// import * as THREE from 'three'
// import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import gsap from 'gsap'

import Experience from './Experience/Experience.js'
const experience = new Experience(document.querySelector('.webgl'))


window.addEventListener("load", () => {
    console.log("Page fully loaded")
    let tl = new gsap.timeline()
    tl.to(".loading-page", {opacity:0, duration:0.5, delay:2})
    tl.to(".main-page", {opacity:1, duration:2}, "-=0.2")
    tl.from("canvas", {opacity: 0, duration: 1, y:50}, "-=1.5")
    tl.from(".navbar li", {opacity: 0, duration:0.5, stagger:0.5, x: -20}, "-=1.5")
    tl.from(".homeText h4", {opacity:0, duration:1, y: 50}, "-=1")
    tl.from(".homeText h1", {opacity:0, duration:1, y: 50}, "-=0.7")
    tl.from(".homeText h3", {opacity:0, duration:1, y: 50}, "-=0.7")
    tl.from(".btn", {opacity: 0, duration:1, y:50}, "-=0.7")
})



var scroll = window.requestAnimationFrame || function(callback) { window.setTimeout(callback,1000/60)};
var elementsToShow = document.querySelectorAll('.onView');
let container = document.querySelector('.container')
let header = document.querySelector('.header')
let popup = document.querySelectorAll('.formazione b, .esperienze b')
let popupClose = document.querySelectorAll('.popup .close')
let contactForm = document.querySelector('.contact form')
let progressValue = document.querySelector('.progress-value')

container.addEventListener('scroll', () => {
    let width = (container.scrollLeft / container.clientWidth) * 50
    progressValue.style.width = width + "%"
})

contactForm.addEventListener('click', (el) => {
    let id = el.target.id
    showInfo("error")
})

popup.forEach(item => {
    item.addEventListener('click', (el) => {
        let id = el.target.id
        showInfo(id)
    })
})

popupClose.forEach(item => {
    item.addEventListener('click', (el) => {
        let id = el.target.id
        hideInfo(id)
    })
})

function showInfo(o){
    let el = document.querySelector('.'+ o)
    el.classList.add('visible')
    container.style.filter = 'blur(10px)'
    header.style.filter = 'blur(10px)'
}

function hideInfo(o){
    let el = document.querySelector('.'+ o)
    el.classList.remove('visible')
    container.style.filter = 'blur(0)'
    header.style.filter = 'blur(0)'
}

function loop(){
    elementsToShow.forEach(function(element){
        if(isElementInViewport(element)){
            element.classList.add('is-visible');
        } else {
            element.classList.remove('is-visible');
        }
    });
    scroll(loop);
}

function isElementInViewport(el){
    if(typeof jQuery === "function" && el instanceof jQuery){
        el = el[0];
    }
    var rect = el.getBoundingClientRect();

    return(
        (rect.left <= 0 && rect.right >=0) || (rect.right >= (window.innerWidth || document.documentElement.clientWidth) && rect.left <= (window.innerWidth || document.documentElement.clientWidth)) || (rect.left >= 0 && rect.right <= (window.innerWidth || document.documentElement.clientWidth))
    );
}

loop();

// /**
//  * Base
//  */
// const container = document.querySelector('.container')
// const canvas = document.querySelector('.webgl')

// const sizes = {
//     width: window.innerWidth,
//     height: window.innerHeight,
// }

// let aspectRatio = sizes.width / sizes.height

// const mouseCoordinates = {
//     x: 0,
//     y: 0
// }

// /**
//  * Scene
//  */
// const scene = new THREE.Scene()
// const axes = new THREE.AxesHelper(5)
// // scene.add(axes)

// /**
//  * Loaders
//  */
// const dracoLoader = new DRACOLoader()
// dracoLoader.setDecoderPath('/draco/')

// const gltfLoader = new GLTFLoader()
// gltfLoader.setDRACOLoader(dracoLoader)

// gltfLoader.load(
//     '/models/laptop.glb',
//     (gltf) => {
//         gltf.scene.position.set(1, -0.5, 0)
//         gltf.scene.rotation.y = -(Math.PI * 0.2)
//         scene.add(gltf.scene)
//     }
// )

// /**
//  * Galaxy
//  */
// const count = 1000 // Number of particles
// const positions = new Float32Array(count * 3) // Particles coordinates

// const geometry = new THREE.BufferGeometry()

// for(let i = 0; i < count; i++){
//     let i3 = i * 3
//     positions[i3] = - (3 - Math.random() * 3 * 6)
//     positions[i3 + 1] = (Math.random() - 0.5) * 5
//     positions[i3 + 2] = (Math.random() - 0.5) * 10
// }
// geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
// // geometry.setAttribute('color', new THREE.BufferAttribute(positions, 3))

// const material = new THREE.PointsMaterial({
//     color: new THREE.Color('#fff'),
//     size: 0.01,
//     sizeAttenuation: true,
//     transparent: true,
//     depthWrite: false
// })

// const particles = new THREE.Points(geometry, material)
// scene.add(particles)

// /**
//  * Light
//  */
// const light = new THREE.DirectionalLight('#fff', 3)
// light.position.set(-1, 1, 2)

// const lightHelper = new THREE.DirectionalLightHelper(light)
// scene.add(light)

// /**
//  * Camera
//  */
// // CameraGroup
// const cameraGroup = new THREE.Group()
// cameraGroup.position.z = 3
// scene.add(cameraGroup)

// // Camera
// const camera = new THREE.PerspectiveCamera(45, aspectRatio, 0.01, 100)
// cameraGroup.add(camera)



// /**
//  * Renderer
//  */
// const renderer = new THREE.WebGLRenderer({
//     canvas: canvas,
//     alpha: true
// })
// renderer.setSize(sizes.width, sizes.height)
// renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// /**
//  * EventListener
//  */
// window.addEventListener('resize', () => {
//     // Update Sizes
//     sizes.width = window.innerWidth
//     sizes.height = window.innerHeight

//     // Update Camera
//     camera.aspect = sizes.width / sizes.height
//     camera.updateProjectionMatrix()

//     // Update Renderer
//     renderer.setSize(sizes.width, sizes.height)
// })

// window.addEventListener('mousemove', (event) => {
//     // Update coordinates
//     mouseCoordinates.x = (event.clientX / sizes.width) * 0.5
//     mouseCoordinates.y = - (event.clientY / sizes.height) * 0.5    
// })

// /**
//  * Tick
//  */
// const tick = () => {

//     // Move camera
//     cameraGroup.position.y = mouseCoordinates.y * 0.5
//     cameraGroup.position.x = mouseCoordinates.x * 0.5
//     camera.position.x = (container.scrollLeft / sizes.width) * 5

//     // Render
//     renderer.render(scene, camera)

//     window.requestAnimationFrame(tick)
// }

// tick()