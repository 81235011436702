import * as THREE from 'three'
import Sizes from "./Utils/Sizes.js";
import Camera from './Camera.js';
import Galaxy from './World/Galaxy.js';
import Renderer from './Renderer.js';
import Time from './Utils/Time.js';
import Laptop from './World/Laptop.js';
import Debug from './Utils/Debug.js';

let instance = null

export default class Experience{
    constructor(canvas){
        // Check instance
        if(instance){
            return instance
        }
        instance = this

        console.log('Inside Experience constructor');


        // Canvas
        this.canvas = canvas

        // Setup
        this.debug = new Debug()
        this.sizes = new Sizes()
        this.time = new Time()
        this.scene = new THREE.Scene()
        this.camera = new Camera()
        this.galaxy = new Galaxy(1000)
        this.laptop = new Laptop()
        this.renderer = new Renderer()

        this.sizes.on('resize', () => {
            this.resize()
        })

        this.time.on('tick', () => {
            this.update()
        })
    }

    resize(){
        this.sizes.resize()
        this.camera.resize()
        this.renderer.resize()
    }

    update(){
        this.debug.check()
        this.camera.update()
        this.renderer.update()
    }
}