import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import Experience from '../Experience'
import * as THREE from 'three'


export default class Laptop{
    constructor(){
        this.experience = new Experience()
        this.scene = this.experience.scene

        // Setup Loader
        this.dracoLoader = new DRACOLoader()
        this.dracoLoader.setDecoderPath('/draco/')

        this.gltfLoader = new GLTFLoader()
        this.gltfLoader.setDRACOLoader(this.dracoLoader)

        this.baked = new THREE.TextureLoader()

        this.material = {}

        this.setMaterial()
        this.setInstance(this.material)
    }

    setMaterial(){
        this.baked.load(
            '/models/baked.jpg',
            (texture) => {
                this.texture = texture
                this.texture.flipY = false
                // this.texture.encoding = THREE.sRGBEncoding
                this.material.base = new THREE.MeshBasicMaterial({map: this.texture})
            }
        )
        this.baked.load(
            '/models/background.jpg',
            (texture) => {
                this.texture = texture
                this.texture.wrapS = THREE.RepeatWrapping
                this.texture.wrapT = THREE.RepeatWrapping
                // this.texture.flipY = false
                this.texture.encoding = THREE.sRGBEncoding
                this.material.screen = new THREE.MeshBasicMaterial({map: this.texture})
            }
        )
    }

    setInstance(material){
        this.gltfLoader.load(
            '/models/laptop.glb',
            (instance) => {
                instance.scene.position.set(1, -0.6, 0)
                instance.scene.rotation.y = -(Math.PI * 0.2)
                this.base = instance.scene.children.find((child) => child.name === 'base')
                this.screen = instance.scene.children.find((child) => child.name === 'screen')
                this.base.material = material.base
                this.screen.material = material.screen
                this.scene.add(instance.scene)
            }
        )
    }
}
