import * as THREE from 'three'

import Experience from '../Experience.js'

export default class Galaxy{
    constructor(count){
        this.count = count
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.positions = new Float32Array(this.count * 3)

        this.setGalaxy()
    }

    setGalaxy(){

        // Geometry
        this.geometry = new THREE.BufferGeometry()

        for(let i=0; i < this.count; i++){
            let i3 = i * 3
            this.positions[i3] = - (3 - Math.random() * 3 * 6)
            this.positions[i3 + 1] = (Math.random() - 0.5) * 5
            this.positions[i3 + 2] = (Math.random() - 0.5) * 10
        }

        this.geometry.setAttribute('position', new THREE.BufferAttribute(this.positions, 3))

        // Material
        this.material = new THREE.PointsMaterial({
            color: new THREE.Color('#fff'),
            size: 0.01,
            sizeAttenuation: true,
            transparent: true,
            depthWrite: false
        })

        // Mesh
        this.galaxy = new THREE.Points(this.geometry, this.material)
        this.scene.add(this.galaxy)
    }
}